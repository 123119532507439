import { ComponentType, CSSProperties } from "react";

interface IconProps {
  Icon: ComponentType<{ className?: string; style?: CSSProperties }>;
  classNameIcon?: string;
  isOpen?: boolean;
}

export const Icon = ({
  Icon: IconComponent,
  classNameIcon,
  isOpen = false,
}: IconProps) => (
  <IconComponent
    className={`order-1 h-5 w-5 text-black-lighter ${
      isOpen
        ? "rotate-90 transition duration-300 ease-in-out"
        : "transition duration-300 ease-in-out"
    } ${classNameIcon}`}
  />
);
