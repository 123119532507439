import { useState, PropsWithChildren, ReactNode } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Icon } from "../../../components";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

interface DataProps {
  id: number;
  content?: ReactNode;
  introContent?: ReactNode;
}

interface AnimatedAccordionProps {
  data: DataProps[];
  classNameContent?: string;
  classNameIntroContent?: string;
}

export const AnimatedAccordion = ({
  data,
  classNameIntroContent,
  classNameContent
}: AnimatedAccordionProps) => {
  const [active, setActive] = useState<number | boolean>(0);

  return (
    <>
      {data?.map((item, i) => (
        <AccordionItem
          key={item.id}
          i={i}
          item={item}
          active={active}
          setActive={setActive}
          classNameIntroContent={classNameIntroContent}
          classNameContent={classNameContent}
        />
      ))}
    </>
  );
};

interface AccordionItemProps {
  i: number;
  item: DataProps;
  active: number | boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean | number>>;
  classNameIntroContent?: string;
  classNameContent?: string;
}

const AccordionItem = ({
  i,
  item,
  active,
  setActive,
  classNameIntroContent,
  classNameContent
}: AccordionItemProps) => {
  const isActive = active === i;

  return (
    item && (
      <AccordionContainer isActive={isActive}>
        <AccordionTitle
          isActive={isActive}
          setActive={setActive}
          i={i}
          classNameIntroContent={classNameIntroContent}
        >
          {!item.introContent && (
            <div className="h-5 w-5 rounded-full bg-gray-dark" />
          )}
          {item.introContent}
        </AccordionTitle>
        <AccordionText isActive={isActive} classNameContent={classNameContent}>
          {!item.content && (
            <>
              <div className="mt-3 h-2 w-full rounded-md bg-gray-dark" />
              <div className="mt-3 h-2 w-full rounded-md bg-gray-dark" />
              <div className="mt-3 h-2 w-full rounded-md bg-gray-dark" />
              <div className="mt-3 h-2 w-full rounded-md bg-gray-dark" />
            </>
          )}
          {item.content}
        </AccordionText>
      </AccordionContainer>
    )
  );
};

const AccordionTitle = ({
  children,
  isActive,
  setActive,
  i,
  classNameIntroContent
}: PropsWithChildren<{
  i: number;
  isActive: boolean;
  setActive: React.Dispatch<React.SetStateAction<number | boolean>>;
  classNameIntroContent?: string;
}>) => (
  <motion.div
    initial={false}
    transition={{ duration: 0.1 }}
    className={`${
      classNameIntroContent
        ? classNameIntroContent
        : "mb-4 h-80 w-full rounded-md bg-opacity-darker p-6"
    } ${
      isActive ? "bg-opacity/100 rounded-b-none" : "bg-opacity/90 rounded-b-md"
    } transition-all`}
    onClick={() => setActive(isActive ? false : i)}
  >
    <>
      {children}
      <ChevronIcon isActive={isActive} />
    </>
  </motion.div>
);

const AccordionText = ({
  children,
  isActive,
  classNameContent
}: PropsWithChildren<{ isActive: boolean; classNameContent?: string }>) => (
  <AnimatePresence initial={false}>
    {isActive && (
      <motion.div
        key="text"
        initial="closed"
        animate="active"
        exit="closed"
        style={{ overflow: "hidden" }}
        variants={{ active: { height: "auto" }, closed: { height: 0 } }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        className="rounded-b-md"
      >
        <div className={`${classNameContent ? classNameContent : "p-4"}`}>
          {children}
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

const AccordionContainer = ({
  children,
  isActive
}: PropsWithChildren<{ isActive: boolean }>) => (
  <motion.div
    initial={false}
    className="mb-3 rounded"
    animate={{
      boxShadow: isActive
        ? "0px 3px 5px 0px rgba(200, 200, 200, 1)"
        : "0px 0px 0px 0px rgba(200, 200, 200, 0)"
    }}
    whileHover={{ boxShadow: "0px 3px 5px 0px rgba(200, 200, 200, 1)" }}
    transition={{ duration: 0.2 }}
  >
    {children}
  </motion.div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ChevronIcon = ({ isActive }: any) => (
  <motion.div
    initial={false}
    animate={{
      color: isActive ? "#fff" : "#333",
      transform: isActive ? "rotate(-180deg)" : "rotate(0deg)"
    }}
    transition={{ duration: 0.4 }}
    className="flex items-center justify-center rounded-full"
  >
    <Icon Icon={ChevronUpIcon} classNameIcon="w-5 h-5 text-black-lightest" />
  </motion.div>
);
