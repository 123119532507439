import { Menu } from "@headlessui/react";
import { PropsWithChildren } from "react";
import { cntl } from "../../../util";

export const MenuDropdownButton = ({
  children,
  className
}: PropsWithChildren<{ className?: string }>) => (
  <Menu.Button
    as="div"
    className={cntl`z-10 w-full cursor-pointer focus:outline-none ${className}`}
  >
    {children}
  </Menu.Button>
);
