import React, { FunctionComponent, PropsWithChildren } from "react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { authInstance } from "./authInstance";

export const MsalAuthenticationProvider: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  return (
    <MsalProvider instance={authInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
