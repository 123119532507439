import { PropsWithChildren } from "react";

export const SidebarHeader = ({
  children,
  id
}: PropsWithChildren<{ id?: string }>) => (
  <div id={id} className="flex flex-col">
    {children}
  </div>
);
