import { ReactNode, useState } from "react";
interface Tab {
  id: string;
  name: string;
  disabled?: boolean;
  onClick?: () => void;
  content: ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  grow?: boolean;
  active?: string;
}

export const Tabs = ({ tabs, grow = true, active }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(active ?? tabs[0]?.id);

  const buttons = tabs.map((tab, i) => {
    const onClick = () => {
      if (tab.disabled) return;
      if (!tab.onClick) setActiveTab(tabs[i].id);
      tab.onClick?.();
      setActiveTab(tabs[i].id);
    };

    return (
      <div key={i} className={grow ? "flex-grow" : "flex-none"}>
        <div
          className={`    cursor-pointer
          px-4
          py-2
          ${tab.disabled ? "opacity-50" : ""}
          ${tab.id === activeTab ? "border-b-2 border-primary" : ""}`}
          onClick={onClick}
        >
          <div className="flex justify-center text-base font-semibold text-black">
            {tab.name}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="flex grow flex-col">
      <div className="flex justify-center shadow">{buttons}</div>
      <div className="grow">
        {tabs.find(tab => tab.id === activeTab)?.content}
      </div>
    </div>
  );
};
