/* eslint-disable */
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";
import { Icon } from "../../../components";
const optionsCN = `
    absolute
    z-10
    bg-white
    shadow-lg
    rounded
    w-48
    max-h-64
    mt-1
    overflow-auto
`;

export type SelectOption = {
  id: string;
  label: string;
  value: any;
};

interface SelectProps {
  renderOption?: (option: SelectOption) => ReactNode;
  selected?: string;
  onChange: (option: SelectOption) => void;
  options: SelectOption[];
  label?: string;
  disabled?: boolean;
}

// const Button = Listbox.Button;
// const Label = Listbox.Label;
// const Option = Listbox.Options;

const FormSelect = (props: SelectProps) => {
  const { renderOption, selected, onChange, options, label, disabled } = props;

  const selectedOption = options.find(({ id }) => id === selected);

  const displayOption = (option: SelectOption) =>
    renderOption?.(option) ?? option.label;

  return (
    <Listbox value={selectedOption} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className="relative">
          {label && (
            <span className="py-1 text-sm font-semibold uppercase font-primary text-black-lighter">
              <Listbox.Label>{label}</Listbox.Label>
            </span>
          )}
          <Listbox.Button
            className={`flex
                        text-base
                        items-center
                        rounded
                        shadow
                        w-full
                        py-2
                        px-3
                        z-20
                        ${disabled ? "bg-disabled" : "bg-white"}`}
          >
            <div className="py-1 text-sm font-semibold uppercase font-primary text-black-lighter">
              {selectedOption && displayOption(selectedOption)}
            </div>
            <div className="h-5 ml-auto">
              <Icon Icon={ChevronUpDownIcon} classNameIcon="black-lightest" />
            </div>
          </Listbox.Button>
          <Transition
            show={open}
            leave="transition duration-100 ease-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options static className={optionsCN}>
              {options.map((option) => (
                <Listbox.Option as="div" key={option.id} value={option}>
                  {({ selected, active }) => (
                    <div
                      className="w-full
                    h-full
                    transition
                    ease-in-out
                    duration-300
                    hover:bg-opacity-darker cursor-pointer"
                    >
                      <div
                        className={`flex
                                    items-center
                                    py-2
                                    select-none
                                    ${active ? "bg-opacity-darker" : ""}
                                    ${
                                      selected
                                        ? "bg-opacity-darker font-semibold"
                                        : ""
                                    }`}
                      >
                        <div className="w-5 pl-3">
                          {selected && <Icon Icon={CheckIcon} />}
                        </div>
                        <div className="ml-4 mr-6 text-base text-black-lighter">
                          {displayOption(option)}
                        </div>
                      </div>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export { FormSelect };
