import { IPermissions } from "../IPermissions";

// IMPORTANT: Don't set any permissions to false to prevent overwritting issues with multiple roles per user.
export const communicationManagerPermissions: IPermissions = {
  tickets: {
    readTicketTable: true,
    readTicketDetails: true,
    readPurchaseDetails: true,
    readLicensePlateDetails: true,
    readTicketUsageTable: true,
  },
  products: {
    readProductsTable: true,
    readProductDetails: true,
    updateBicycleProduct: true,
    updateMotorcycleProduct: true,
    updateVehicleProduct: true,
    updatePedestrianProduct: true,
    updateMiscProduct: true,
    productProperties: {
      setLocalizations: true,
      setTicketTemplates: true,
    },
  },
  productCategories: {
    readProductCategories: true,
  },
  accounts: {
    readAccountsTable: true,
    readAccountDetails: true,
    readAccountLicensePlates: true,
    readAccountChipcards: true,
    readAccountTickets: true,
    readChipcardDetails: true,
  },
  ticketTemplates: {
    readTicketTemplatesTable: true,
    updateTicketTemplates: true,
  },
  shareholderMeetings: {
    readShareholderMeetingsTable: true,
    readShareholderMeetingsDetails: true,
  },
};
