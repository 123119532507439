import { RoleEnum } from "../../enums/RoleEnum";
import { IPermissions } from "./IPermissions";
import { adminPermissions } from "./permissions/AdminPermissions";
import { applicationManagerPermissions } from "./permissions/ApplicationManagerPermissions";
import { communicationManagerPermissions } from "./permissions/CommunicationManagerPermissions";
import { customerManagerPermissions } from "./permissions/CustomerManagerPermissions";
import { financeAccountancyManagerPermissions } from "./permissions/FinanceAccountancyManagerPermissions";
import { productManagerPermissions } from "./permissions/ProductManagerPermissions";
import { salesEmployeePermissions } from "./permissions/SalesEmployeePermissions";
import { shareholderManagerPermissions } from "./permissions/ShareholderManagerPermissions";
import { shareholderMeetingManagerPermissions } from "./permissions/ShareholderMeetingManagerPermissions";

export const getPermissionsBasedOnRoles = (roles: RoleEnum[]): IPermissions => {
  if (roles.length < 1) {
    return {};
  }

  let permissions: IPermissions = {};

  roles.forEach((roleEnum) => {
    const individualPermission = getPermissionsBasedOnRole(roleEnum);
    permissions = {
      tickets: {
        ...permissions.tickets,
        ...individualPermission.tickets,
      },
      customers: {
        ...permissions.customers,
        ...individualPermission.customers,
      },
      products: {
        ...permissions.products,
        ...individualPermission.products,
      },
      licensePlates: {
        ...permissions.licensePlates,
        ...individualPermission.licensePlates,
      },
      accounts: {
        ...permissions.accounts,
        ...individualPermission.accounts,
      },
      financialTransactions: {
        ...permissions.financialTransactions,
        ...individualPermission.financialTransactions,
      },
      shareholders: {
        ...permissions.shareholders,
        ...individualPermission.shareholders,
      },
      deliveryMethods: {
        ...permissions.deliveryMethods,
        ...individualPermission.deliveryMethods,
      },
      productCategories: {
        ...permissions.productCategories,
        ...individualPermission.productCategories,
      },
      ticketTemplates: {
        ...permissions.ticketTemplates,
        ...individualPermission.ticketTemplates,
      },
      salesChannels: {
        ...permissions.salesChannels,
        ...individualPermission.salesChannels,
      },
      salesChannelUnits: {
        ...permissions.salesChannelUnits,
        ...individualPermission.salesChannelUnits,
      },
      checkpoints: {
        ...permissions.checkpoints,
        ...individualPermission.checkpoints,
      },
      checkpointTypes: {
        ...permissions.checkpointTypes,
        ...individualPermission.checkpointTypes,
      },
      paymentMethods: {
        ...permissions.paymentMethods,
        ...individualPermission.paymentMethods,
      },
      paymentProviders: {
        ...permissions.paymentProviders,
        ...individualPermission.paymentProviders,
      },
      validitySchedules: {
        ...permissions.validitySchedules,
        ...individualPermission.validitySchedules,
      },
      shareholderMeetings: {
        ...permissions.shareholderMeetings,
        ...individualPermission.shareholderMeetings,
      },
    };
  });

  return permissions;
};

export const getPermissionsBasedOnRole = (role?: RoleEnum): IPermissions => {
  if (!role) {
    return {};
  }

  return (
    {
      [RoleEnum.ADMIN]: adminPermissions,
      [RoleEnum.PRODUCT_MANAGER]: productManagerPermissions,
      [RoleEnum.COMMUNICATION_MANAGER]: communicationManagerPermissions,
      [RoleEnum.CUSTOMER_MANAGER]: customerManagerPermissions,
      [RoleEnum.SALES_EMPLYOEE]: salesEmployeePermissions,
      [RoleEnum.FINANCE_ACCOUNTANCY_MANAGER]:
        financeAccountancyManagerPermissions,
      [RoleEnum.SHAREHOLDER_MANAGER]: shareholderManagerPermissions,
      [RoleEnum.SHAREHOLDER_MEETING_MANAGER]:
        shareholderMeetingManagerPermissions,
      [RoleEnum.APPLICATION_MANAGER]: applicationManagerPermissions,
    }[role] ?? {}
  );
};
