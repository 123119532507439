import { Configuration, PopupRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_AUTHORITY ?? "",
    redirectUri: window.location.origin,
  },
};

export const loginRequest: PopupRequest = {
  scopes: ["openid"],
};
