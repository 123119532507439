import { ComponentType } from "react";
import { cntl } from "../../../util";
import { TextIcon } from "./TextIcon";

interface HeadingProps {
  Icon: ComponentType<{ className?: string }>;
  text: string;
  iconColor?: string;
  textCenter?: boolean;
}

export const TextHeading = ({
  Icon,
  text,
  iconColor,
  textCenter
}: HeadingProps) => (
  <TextIcon
    Icon={Icon}
    text={text}
    iconSize={6}
    iconColor={iconColor}
    color="black-active"
    textClassName="text-xl font-semibold"
    wrapperClassName={cntl`mb-4 ${textCenter ? "justify-center" : ""}`}
  />
);
