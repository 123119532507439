export enum RoleEnum {
  ADMIN = "Admin",
  PRODUCT_MANAGER = "ProductManager",
  COMMUNICATION_MANAGER = "CommunicationManager",
  CUSTOMER_MANAGER = "CustomerManager",
  SALES_EMPLYOEE = "SalesEmployee",
  FINANCE_ACCOUNTANCY_MANAGER = "FinanceAccountancyManager",
  SHAREHOLDER_MANAGER = "ShareholderManager",
  SHAREHOLDER_MEETING_MANAGER = "ShareholderMeetingManager",
  APPLICATION_MANAGER = "ApplicationManager",
}
