import { PropsWithChildren } from "react";
interface SidebarLayoutProps {
  id?: string;
  showSidebar?: boolean;
  isCollapsed?: boolean;
}

export const SidebarLayout = (props: PropsWithChildren<SidebarLayoutProps>) => {
  const { id, showSidebar, isCollapsed, children } = props;

  return (
    <div
      id={id}
      className={`h-screen
    w-64
    flex-none
    duration-300
    ease-in
    ${
      isCollapsed
        ? `absolute py-12 ${showSidebar ? "ml-0" : "-ml-64"}`
        : "sticky shadow"
    }`}
    >
      <div
        className={`group
        z-30
        flex
        flex-col
        overflow-auto
        bg-gray
        ${
          isCollapsed
            ? "absolute inset-y-12 w-64 rounded-r shadow-lg"
            : "h-screen"
        }`}
      >
        {children}
      </div>
    </div>
  );
};
