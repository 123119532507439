export const configurationRoutes = [
  "/delivery-methods",
  "/ticket-templates",
  "/sales-channels",
  "/sales-channel-units",
  "/product-categories",
  "/checkpoints",
  "/checkpoint-types",
  "/payment-methods",
  "/payment-providers",
  "/validity-schedules",
  "/shareholder-meetings",
];
