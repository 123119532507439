import { cntl } from "../../../util";

const textCN = (color?: string, small?: boolean, className?: string) => cntl`
    ${small ? "text-sm" : "text-base"}
    font-primary
    ${color ? `text-${color}` : "text-black-lighter"}
    ${className}
`;

interface TextProps {
  text: string;
  color?: string;
  small?: boolean;
  className?: string;
}

export const Text = ({ text, color, small, className }: TextProps) => (
  <div className={textCN(color, small, className)}>{text}</div>
);
