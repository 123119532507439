import { IPermissions } from "../IPermissions";

// IMPORTANT: Don't set any permissions to false to prevent overwritting issues with multiple roles per user.
export const customerManagerPermissions: IPermissions = {
  tickets: {
    readTicketTable: true,
    readTicketDetails: true,
    readPurchaseDetails: true,
    readChipcardDetails: true,
    readLicensePlateDetails: true,
    readTicketUsageTable: true,
    validateTicket: true,
    refundTicket: true,
    restoreTicketCredit: true,
    updateLicensePlates: true,
    updateAnonymousCustomers: true,
    resendTickets: true,
  },
  customers: {
    updateChipcard: true,
    blockChipcard: true,
    unblockChipcard: true,
    replaceChipcard: true,
    readChipcardDetails: true,
    createCompany: true,
    readCompanyContacts: true,
    readCompanyDetails: true,
    updateCompany: true,
    addCompanyContact: true,
    removeCompanyContact: true,
    updateCompanyContact: true,
    deleteCustomer: true,
    restoreCustomer: true,
    addPersonalizedChipcard: true,
    connectChipcard: true,
    disconnectChipcard: true,
    readCustomerTable: true,
    readCustomerLicensePlates: true,
    disconnectCustomerLicensePlate: true,
    connectCustomerLicensePlate: true,
    readCustomerChipcards: true,
    readCustomerTickets: true,
    readPersonDetails: true,
    createPerson: true,
    updatePerson: true,
    deleteChipcard: true,
    removeStakeholderLogo: true,
  },
  licensePlates: {
    readLicensePlatesTable: true,
    readLicensePlateDetails: true,
    createLicensePlate: true,
    checkLicensePlateInfo: true,
    updateLicensePlateInfo: true,
    changeLicensePlateRegistrationStatus: true,
    updateLeaseInfo: true,
    clearLeaseInfo: true,
  },
  accounts: {
    readAccountsTable: true,
    readAccountDetails: true,
    createAccount: true,
    updateAccount: true,
    readAccountLicensePlates: true,
    connectAccountLicensePlate: true,
    disconnectAccountLicensePlate: true,
    sendPasswordResetEmail: true,
    readAccountChipcards: true,
    connectChipcard: true,
    disconnectChipcard: true,
    readAccountTickets: true,
    readChipcardDetails: true,
    updateChipcard: true,
    blockChipcard: true,
    unblockChipcard: true,
    replaceChipcard: true,
  },
  shareholders: {
    readShareholderShares: true,
  },
};
