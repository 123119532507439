import { Disclosure as HeadlessDisclosure } from "@headlessui/react";
import { JSXElementConstructor, ReactElement } from "react";

export const AccordionButton = ({
  children
}: {
  children?:
    | ReactElement<string, string | JSXElementConstructor<string>>
    | (({
        open
      }: {
        open: boolean;
      }) => ReactElement<string, string | JSXElementConstructor<string>>);
}) => (
  <HeadlessDisclosure.Button as="div" className="w-full focus:outline-none">
    {children}
  </HeadlessDisclosure.Button>
);
