import React, { FunctionComponent, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthorizationContext } from "../../authentication/AuthorizationProvider";
import LoadingSpinner from "../../components/LoadingSpinner";
import routes, { getHomeUrlBasedOnPermissions } from "../../routes/routes";
import Forbidden from "../errorPages/Forbidden";
import ErrorBoundaryTracker from "../ErrorBoundaryTracker";
import { useMsal } from "@azure/msal-react";

const Main: FunctionComponent = () => {
  const { permissions } = useAuthorizationContext();
  const { instance } = useMsal();
  const username = instance.getActiveAccount()?.username;

  const homeUrl = getHomeUrlBasedOnPermissions(permissions);

  return (
    <div className="flex-auto p-4 overflow-auto">
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {routes.map((route, id) => {
            return (
              <Route
                key={id}
                path={route.path}
                element={
                  route.userHasCorrectPermissions(permissions) ? (
                    <ErrorBoundaryTracker
                      metadata={{
                        path: route.path,
                        pathTitle: route.title,
                        username: username,
                      }}
                    >
                      <route.component />
                    </ErrorBoundaryTracker>
                  ) : (
                    <Forbidden />
                  )
                }
              />
            );
          })}
          <Route path="*" element={<Navigate to={homeUrl} />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default Main;
