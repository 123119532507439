import React, { FunctionComponent } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SideBar from "./common/layout/SideBar";
import Header from "./common/layout/Header";
import interceptors from "./interceptors";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "./bbprojects/components";
import "react-toastify/dist/ReactToastify.min.css";
import { AuthorizationProvider } from "./authentication/AuthorizationProvider";
import Main from "./common/layout/Main";
import { MsalProvider } from "@azure/msal-react";
import { authInstance } from "./authentication/authInstance";
import ErrorBoundaryTracker from "./common/ErrorBoundaryTracker";

interceptors.setupInterceptors();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: FunctionComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthorizationProvider>
        <Router>
          <MsalProvider instance={authInstance}>
            <div className="flex h-screen overflow-hidden">
              <SideBar />
              <div className="flex flex-col w-full h-screen bg-white-off overflow-x-auto">
                <Header />
                <ErrorBoundaryTracker>
                  <Main />
                </ErrorBoundaryTracker>
              </div>
              <ToastContainer />
            </div>
          </MsalProvider>
        </Router>
      </AuthorizationProvider>
    </QueryClientProvider>
  );
};

export default App;
