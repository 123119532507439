import React, { FunctionComponent, useState } from "react";
import {
  FormSelect,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ModalLayout,
  SelectOption,
} from "../../../bbprojects/components";
import NoIcon from "../../../components/NoIcon";
import i18n from "../../../i18n";
import { LanguageEnum } from "../../../enums/LanguageEnum";
import { useTranslation } from "react-i18next";
import { Button } from "../../../bbprojects/tables/bbprojects/components/base";

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const LanguageSelectorModal: FunctionComponent<Props> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("langData") || LanguageEnum.NL,
  );

  const { t } = useTranslation();

  const languageOptions: SelectOption[] = [
    { id: LanguageEnum.NL, value: LanguageEnum.NL, label: "Nederlands" },
    { id: LanguageEnum.EN, value: LanguageEnum.EN, label: "English" },
  ];

  const onSubmitHandler = () => {
    localStorage.setItem("langData", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    onSubmit();
  };

  const onCloseHandler = () => {
    onClose();
    setSelectedLanguage(localStorage.getItem("langData") || LanguageEnum.NL);
  };

  const buttons: ModalButton[] = [
    {
      name: t("Shared.Cancel"),
      color: "default",
      Icon: NoIcon,
      onClick: onCloseHandler,
    },
    {
      name: t("Shared.Submit"),
      color: "default",
      Icon: NoIcon,
      onClick: onSubmitHandler,
      className: "bg-primary",
    },
  ];

  return (
    <ModalLayout onClose={onCloseHandler} isOpen={isOpen}>
      <ModalHeader text={t("LanguageSelectorModal.Title")} />
      <ModalBody>
        <FormSelect
          selected={selectedLanguage}
          onChange={(value) => setSelectedLanguage(value.id)}
          options={languageOptions}
        />
      </ModalBody>
      <ModalFooter>
        {buttons.map(({ name, color, onClick, disabled, className }, i) => (
          <Button
            key={i}
            text={name}
            color={color}
            onClick={onClick}
            disabled={disabled}
            className={`shadow ml-auto ${className}`}
            large
          />
        ))}
      </ModalFooter>
    </ModalLayout>
  );
};

export default LanguageSelectorModal;
