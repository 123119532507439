import { Dialog } from "@headlessui/react";

export const ModalHeader = ({ text }: { text: string }) => (
  <Dialog.Title
    className={`    flex
  rounded-t
  border-b
  border-table-x
  bg-gray
  px-6
  py-4
  text-lg
  font-medium
  text-black-dark`}
  >
    {text}
  </Dialog.Title>
);
