import React, { FunctionComponent } from "react";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import {
  ArrowPathIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import {
  Button,
  TextIcon,
} from "../bbprojects/tables/bbprojects/components/base";

const ErrorFallback: FunctionComponent<FallbackProps> = ({
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex-auto p-4 overflow-auto">
      <div className="flex flex-col mx-auto space-y-0">
        <div className="flex-col">
          <TextIcon
            text={t("Errors.SomethingWentWrong")}
            Icon={ExclamationCircleIcon}
            textSize="large"
          />
          <Button
            text={t("Errors.TryAgain")}
            Icon={ArrowPathIcon}
            onClick={resetErrorBoundary}
            color="default"
            className="py-1 px-2 bg-primary mt-5"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
