import { ComponentType, CSSProperties } from "react";
import { cntl } from "../../../util";

interface IconProps {
  Icon: ComponentType<{ className?: string; style?: CSSProperties }>;
  size?: number;
  color?: string;
  className?: string;
  style?: CSSProperties;
}

export const Icon = ({
  Icon: IconComponent,
  className,
  size,
  color,
  style
}: IconProps) => (
  <IconComponent
    className={cntl`flex-none w-${size ?? 4} h-${size ?? 4} ${
      color ? `text-${color}` : ""
    } ${className ?? ""}`}
    style={style}
  />
);
