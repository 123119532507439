import { IPermissions } from "../IPermissions";

// IMPORTANT: Don't set any permissions to false to prevent overwritting issues with multiple roles per user.
export const shareholderManagerPermissions: IPermissions = {
  customers: {
    readChipcardDetails: true,
    readCompanyDetails: true,
    updateCompany: true,
    addCompanyContact: true,
    removeCompanyContact: true,
    updateCompanyContact: true,
    readCustomerTable: true,
    readCustomerLicensePlates: true,
    disconnectCustomerLicensePlate: true,
    connectCustomerLicensePlate: true,
    readCustomerChipcards: true,
    readCustomerTickets: true,
    readPersonDetails: true,
    createPerson: true,
    updatePerson: true,
    removeStakeholderLogo: true,
  },
  products: {
    readProductsTable: true,
    readProductDetails: true,
  },
  shareholders: {
    readShareholderShares: true,
  },
  shareholderMeetings: {
    readShareholderMeetingsTable: true,
    readShareholderMeetingsDetails: true,
    addShareholderMeeting: true,
    updateShareholderMeeting: true,
    addParticipant: true,
  },
};
