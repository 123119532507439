import { motion, Variants } from "framer-motion";

interface TextProps {
  text: string;
  animationUnderlineProp?: string;
  animationChildren?: Variants;
  classNameText?: string;
}

export const Text = ({
  text,
  animationUnderlineProp,
  animationChildren,
  classNameText,
}: TextProps) => {
  const className =
    classNameText ?? "py-1 text-sm font-semibold uppercase font-primary ";
  return (
    <motion.div
      {...animationChildren}
      className={` text-black-lighter ${className} ${animationUnderlineProp}`}
    >
      {text}
    </motion.div>
  );
};
