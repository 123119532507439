import { ComponentType, MouseEventHandler, ReactNode } from "react";
import { Icon, Text } from "../../../components";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  icon?: ComponentType<{ className?: string }>;
  classNameButton?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
  submit?: boolean;
  children?: ReactNode;
  classNameIcon?: string;
}

export const Button = (props: ButtonProps) => {
  const {
    text,
    icon,
    classNameButton,
    disabled = false,
    onClick,
    id,
    submit,
    children,
    classNameIcon
  } = props;

  const content = (
    <div>
      {text || icon ? (
        <>
          {text ? <Text text={text} /> : <></>}
          {icon ? <Icon Icon={icon} classNameIcon={classNameIcon} /> : <></>}
        </>
      ) : (
        children
      )}
    </div>
  );

  return (
    <button
      id={id}
      className={`flex flex-row-reverse items-center gap-2 px-4 py-2 ${
        icon && "justify-end"
      } ${
        disabled ? "cursor-not-allowed bg-black-lightest" : "cursor-pointer"
      } ${classNameButton}`}
      type={submit ? "submit" : "button"}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {content}
    </button>
  );
};
