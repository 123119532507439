import { PropsWithChildren } from "react";
import { cntl } from "../../../util";

const animationCN = (rounded?: boolean, className?: string) => cntl`
    w-full
    h-full
    transition
    ease-in-out
    duration-300
    hover:bg-opacity-darker
    ${rounded ? "rounded" : ""}
    ${className ?? ""}
`;

interface HoverDarkProps {
  rounded?: boolean;
  className?: string;
}

export const HoverDark = ({
  children,
  rounded,
  className
}: PropsWithChildren<HoverDarkProps>) => (
  <div className={animationCN(rounded, className)}>{children}</div>
);
