/* eslint-disable */

import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/solid";
import {ReactNode} from "react";
import {cntl} from "../../../util";
import {HoverDark} from "../../abstracts/hovers/HoverDark";
import {Icon} from "../../atoms/icons/Icon";

const buttonCN = (disabled?: boolean) => cntl`
    flex
    text-base
    items-center
    rounded
    shadow
    w-full
    py-2
    px-3
    z-20
    ${disabled ? "bg-disabled" : "bg-white"}
`;

const optionsCN = cntl`
    absolute
    z-10
    bg-white
    shadow-lg
    rounded
    w-48
    max-h-64
    mt-1
    overflow-auto
`;

const optionCN = (selected: boolean, active: boolean) => cntl`
    flex
    items-center
    py-2
    select-none
    ${active ? "bg-opacity-darker" : ""}
    ${selected ? "bg-opacity-darker font-semibold" : ""}
`;

export type SelectOption = {
    id: string;
    label: string;
    value: any;
};

interface SelectProps {
    renderOption?: (option: SelectOption) => ReactNode;
    selected?: string;
    onChange: (option: SelectOption) => void;
    options: SelectOption[];
    label?: string;
    disabled?: boolean;
}

const FormSelect = (props: SelectProps) => {
    const {renderOption, selected, onChange, options, label, disabled} = props;

    const selectedOption = options.find(({id}) => id === selected);

    const displayOption = (option: SelectOption) => renderOption?.(option) ?? option.label;

    return (
        <Listbox value={selectedOption} onChange={onChange} disabled={disabled}>
            {({open}) => (
                <div className="relative">
                    {label && (
                        <span className="py-1 text-sm font-semibold uppercase font-primary text-black-lighter">
                            <Listbox.Label>{label}</Listbox.Label>
                        </span>
                    )}
                    <Listbox.Button className={buttonCN(disabled)}>
                        <div className="py-1 text-sm font-semibold uppercase font-primary text-black-lighter">
                            {selectedOption && displayOption(selectedOption)}
                        </div>
                        <div className="h-5 ml-auto">
                            <Icon Icon={ChevronUpDownIcon} color="black-lightest" />
                        </div>
                    </Listbox.Button>
                    <Transition
                        show={open}
                        leave="transition duration-100 ease-out"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options static className={optionsCN}>
                            {options.map(option => (
                                <Listbox.Option as="div" key={option.id} value={option}>
                                    {({selected, active}) => (
                                        <HoverDark className="cursor-pointer">
                                            <div className={optionCN(selected, active)}>
                                                <div className="w-5 pl-3">{selected && <Icon Icon={CheckIcon} />}</div>
                                                <div className="ml-4 mr-6 text-base text-black-lighter">
                                                    {displayOption(option)}
                                                </div>
                                            </div>
                                        </HoverDark>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            )}
        </Listbox>
    );
};

export {FormSelect};
