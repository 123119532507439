import { ChangeEventHandler, InputHTMLAttributes } from "react";
import { cntl } from "../../../util";
import { Text } from "../typography";

const checkboxCN = (classNames?: string) => cntl`
    border-2
    border-black-active
    text-primary
    border-0
    cursor-pointer
    focus:shadow-transparent
    shadow-transparent
    outline-none
    focus:ring-0
    focus:ring-offset-0
    ${classNames}
`;

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  text?: string;
}

export const FormCheckbox = (props: CheckboxProps) => {
  const { text, id, className } = props;
  return (
    <div className="flex items-center">
      <input {...props} type="checkbox" className={checkboxCN(className)} />
      {text && (
        <label htmlFor={id} className="mb-0 ml-2 cursor-pointer">
          <Text text={text} />
        </label>
      )}
    </div>
  );
};
