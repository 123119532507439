import {
  ChevronUpDownIcon,
  StarIcon,
  BeakerIcon,
} from "@heroicons/react/24/solid";
import { Icon } from "../../data-display/icons/Icon";

import { Text } from "../../general/typography/Text";
import { motion, Variants } from "framer-motion";
import { ComponentType, PropsWithChildren, ReactNode, useState } from "react";
import { Button } from "../../../tables/bbprojects/components/base";

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "tween" },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

interface DataProps {
  id: string | number;
  title?: string;
  icon?: ComponentType<{ className?: string }>;
}

interface AnimatedDropdownMenuProps {
  children?: ReactNode;
  data?: DataProps[];
}
//TODO mora on change handler da ima
const AnimatedDropdownMenu = ({
  children,
  data,
}: AnimatedDropdownMenuProps) => {
  const testData = [
    { id: 1, title: "Item 1", icon: StarIcon },
    { id: 2, title: "Item 2", icon: BeakerIcon },
    { id: 3, title: "Item 3", icon: StarIcon },
    { id: 4, title: "Item 4", icon: BeakerIcon },
  ];
  const [isOpen, setIsOpen] = useState(false);

  const [selected, setSelected] = useState<DataProps>(testData[0]);
  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="relative"
    >
      <motion.button
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
        className="flex select-none items-center gap-4 p-2 pt-3 text-lg shadow-md"
      >
        {/*TODO check that selection works, after confirmation remove this comment*/}
        <Icon
          Icon={ChevronUpDownIcon}
          classNameIcon="mt-0.5 text-black w-5 h-5"
        />
        <div className="flex items-center gap-2">
          {selected && (
            <>
              <Text
                text={selected.title! ?? "Provide title"}
                classNameText="text-black-light text-lg font-semibold"
              />
              <Icon
                Icon={selected.icon ?? StarIcon}
                classNameIcon="text-primary w-5 h-5"
              />
            </>
          )}
        </div>
      </motion.button>
      <motion.ul
        variants={{
          open: {
            opacity: 1,
            y: 0,
            transition: {
              type: "tween",
              duration: 0.3,
              staggerChildren: 0.1,
            },
          },
          closed: {
            opacity: 0,
            y: -20,
            transition: {
              type: "tween",
              duration: 0.3,
            },
          },
        }}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
        className={`absolute left-0 top-12 z-40 w-[100%] overflow-hidden rounded bg-white shadow-lg ${
          isOpen ? "pointer-events-auto" : "pointer-events-none"
        }`}
      >
        <AnimatedDropdownMenuContent
          setIsOpen={setIsOpen}
          data={data}
          setSelected={setSelected}
        >
          {children}
        </AnimatedDropdownMenuContent>
      </motion.ul>
    </motion.nav>
  );
};

const AnimatedDropdownMenuContent = ({
  setIsOpen,
  data,
  setSelected,
}: PropsWithChildren<{
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data?: DataProps[];
  setSelected: (newValue: DataProps) => void;
}>) => (
  <>
    {data?.map(item => (
      <motion.li
        variants={itemVariants}
        onClick={() => {
          setIsOpen(false);
          setSelected(item);
        }}
        key={item.id}
      >
        <Button Icon={item.icon} text={item.title ?? ""} />
      </motion.li>
    ))}
  </>
);

export { AnimatedDropdownMenu };
