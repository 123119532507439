import { InputHTMLAttributes } from "react";
import { cntl } from "../../../util";
import { TextDescriptor } from "../typography";

const inputCN = (
  invalid?: boolean,
  disabled?: boolean,
  resizable?: boolean
) => cntl`
    flex
    items-center
    rounded
    shadow
    w-full
    py-2
    px-3
    ${disabled ? "bg-disabled cursor-not-allowed" : "bg-white"}
    ${invalid && "ring-warning ring-2"}
    ${!resizable && "resize-none"}
    border-0
    text-black
    text-base
    transition
    ease-in-out
    duration-300
    outline-none
    ring-none
    focus:ring-primary
    focus:ring-2
    focus:ring-opacity-70
    focus:outline-primary
`;

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  className?: string;
  labelClassName?: string;
  /**
   * Only allow the text-based types from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types,
   * or `textarea`
   */
  type?:
    | "date"
    | "datetime-local"
    | "email"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "textarea"
    | "time"
    | "url"
    | "week";
  invalid?: boolean;
  /** `feedback` is only displayed when `invalid === true` */
  feedback?: string;
  label?: string;
}

export const FormInput = (props: FormInputProps) => {
  const {
    className,
    id,
    labelClassName,
    label,
    type,
    invalid,
    feedback,
    disabled
  } = props;

  const inputElement = (
    <input {...props} type={type} className={inputCN(invalid, disabled)} />
  );
  return (
    <div className={`flex flex-col ${className}`}>
      {label ? (
        <label htmlFor={id} className={`mb-0 ${labelClassName}`}>
          <TextDescriptor text={label} />
          {inputElement}
        </label>
      ) : (
        inputElement
      )}
      {invalid && feedback && <div className="text-warning">{feedback}</div>}
    </div>
  );
};
