import { Menu, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";

export const MenuDropdownItems = ({
  children,
  open,
  left
}: PropsWithChildren<{ open: boolean; left?: boolean }>) => (
  <Transition
    show={open}
    enter="transition ease-out duration-300 transform"
    enterFrom="translate-y-4 opacity-0 scale-95"
    enterTo="translate-y-0 opacity-100 scale-100"
    leave="transition ease-in duration-75 transform"
    leaveFrom="translate-y-0 opacity-100 scale-100"
    leaveTo="translate-y-4 opacity-0 scale-95"
    as={Fragment}
  >
    <Menu.Items
      className={`    absolute
    ${left ? "left-6" : "right-0"}
    z-40
    w-[100%]
    overflow-hidden
    rounded
    bg-white
    shadow-lg`}
      static
    >
      {children}
    </Menu.Items>
  </Transition>
);
