import { ComponentType, MouseEventHandler, ReactNode } from "react";
import { Button } from "../../../components";
import { ModalBody } from "./ModalBody";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";
import { ModalLayout } from "./ModalLayout";

export interface ModalButton {
  name: string;
  Icon: ComponentType<{ className?: string }>;
  color?: string;
  disabled?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}

interface ModalProps {
  modalName: string;
  isOpen: boolean;
  onClose: () => void;
  buttons: ModalButton[];
  // TODO else translation goes wrong in overrides
  children?: ReactNode;
}

export const Modal = (props: ModalProps) => {
  const { modalName, buttons, isOpen, onClose, children } = props;

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <ModalHeader text={modalName} />
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {buttons.map(({ name, Icon, color, onClick, disabled }, i) => (
          <Button
            key={i}
            text={name}
            icon={Icon}
            color={color}
            onClick={onClick}
            disabled={disabled}
            className="shadow"
          />
        ))}
      </ModalFooter>
    </ModalLayout>
  );
};
