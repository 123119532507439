import { InputHTMLAttributes, useState, useEffect } from "react";
import { Text } from "../../../components";

const inputStyles = `
  flex items-center rounded shadow w-full py-2 px-3  resize-none border-0 text-black text-base transition ease-in-out duration-300 outline-none ring-none focus:ring-primary focus:ring-2 focus:ring-opacity-70 focus:outline-primary
`;

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  // id: string; TODO: check why this is commented out, we probably need an id prop
  inputwrapper?: string;
  classNameLabel?: string;
  classNameInput?: string;
  type?:
    | "date"
    | "datetime-local"
    | "email"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "textarea"
    | "time"
    | "url"
    | "week"
    | "checkbox"
    | "radio";
  invalid?: boolean;
  /** `feedback` is only displayed when `invalid === true` */
  feedback?: string;
  label?: string;

  /* novi propovi za radio btn*/
  name?: string;
  buttons?: Array<{ id: string; text: string; disabled?: boolean }>;
  selected?: string;
  onChange?: (selected: any) => void;
}

export const FormInput = (props: FormInputProps) => {
  const {
    className,
    id,
    classNameLabel,
    label,
    classNameInput,
    type,
    invalid,
    feedback,
    buttons,
    selected,
    onChange,
    name,
    disabled,
  } = props;

  const classNameRequired = feedback ? "ring-warning ring-2" : "";
  const [current, setCurrent] = useState(selected);
  const disabledStyle = disabled ? "bg-disabled" : "";

  useEffect(() => {
    setCurrent(selected);
  }, [selected]);

  const onRadioSelect = (id: string) => {
    setCurrent(id);
    onChange?.(id);
  };

  const inputElement = (
    <input
      {...props}
      type={type}
      className={`${inputStyles} ${classNameInput} ${disabledStyle} ${classNameRequired}`}
    />
  );

  return (
    <div className={`flex flex-col ${className}`}>
      {label ? (
        <label htmlFor={id} className={`mb-0  ${classNameLabel}`}>
          <Text text={label} />
          {inputElement}
        </label>
      ) : type == "radio" ? (
        <>
          {buttons?.map(({ id, text, disabled }) => (
            <div key={id} className="flex items-center">
              <label
                htmlFor={id}
                className={`flex
                cursor-pointer
            gap-2
            rounded-full
            border-0 text-black-lighter
            outline-none
            focus:ring-0
            focus:ring-offset-0
            disabled:cursor-default
            disabled:opacity-50 ${classNameLabel}`}
              >
                <input
                  id={id}
                  checked={id === current}
                  type="radio"
                  name={name}
                  disabled={disabled}
                  onChange={e => e.target.value === "on" && onRadioSelect(id)}
                  className={` ${classNameInput} `}
                />

                <Text
                  classNameText="
                        text-sm
                        font-semibold
                        uppercase
                        font-primary"
                  text={text}
                />
              </label>
            </div>
          ))}
        </>
      ) : (
        inputElement
      )}

      {invalid && feedback && (
        <div className="text-warning">{feedback.toString()}</div>
      )}
    </div>
  );
};
