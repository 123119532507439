import apiClient from "../../api/client";

const getBackendVersion = async (): Promise<string> => {
  const result = await apiClient.get<string>("/version");
  return result.data;
};

export const BackendVersionApi = {
  getVersion: getBackendVersion,
};
