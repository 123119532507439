import { ComponentType, MouseEventHandler } from "react";
import { Button } from "./Button";

interface ButtonItemProps {
  text: string;
  Icon?: ComponentType<{ className?: string }>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
}

export const ButtonItem = ({
  text,
  Icon,
  onClick,
  active
}: ButtonItemProps) => (
  <Button
    text={text}
    Icon={Icon}
    onClick={onClick}
    fullWidth={true}
    hover={true}
    rounded={false}
    active={active}
  />
);
