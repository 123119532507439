import { IPermissions } from "../IPermissions";

// IMPORTANT: Don't set any permissions to false to prevent overwritting issues with multiple roles per user.
export const productManagerPermissions: IPermissions = {
  products: {
    readProductsTable: true,
    deleteProduct: true,
    readProductDetails: true,
    updateBicycleProduct: true,
    updateMotorcycleProduct: true,
    updateVehicleProduct: true,
    updatePedestrianProduct: true,
    updateMiscProduct: true,
    createProduct: true,
    productProperties: {
      setAll: true,
    },
  },
  deliveryMethods: {
    readDeliveryMethodsTable: true,
    deleteDeliveryMethod: true,
    addDeliveryMethod: true,
    updateDeliveryMethod: true,
  },
  productCategories: {
    readProductCategories: true,
    updateProductCategory: true,
  },
  ticketTemplates: {
    readTicketTemplatesTable: true,
    updateTicketTemplates: true,
  },
  salesChannels: {
    readSalesChannelsTable: true,
    addSalesChannel: true,
    updateSalesChannel: true,
    deleteSalesChannel: true,
  },
  salesChannelUnits: {
    readSalesChannelUnitsTable: true,
    readSalesChannelUnitDetails: true,
    addSalesChannelUnit: true,
    updateSalesChannelUnit: true,
    deleteSalesChannelUnit: true,
  },
  checkpoints: {
    readCheckpointsTable: true,
    addCheckpoint: true,
    readCheckpointDetails: true,
    updateCheckpoint: true,
    deleteCheckpoint: true,
  },
  checkpointTypes: {
    readCheckpointTypesTable: true,
    readCheckpointTypeDetails: true,
  },
  validitySchedules: {
    readValiditySchedulesTable: true,
    readValiditySchedulesDetails: true,
    addValiditySchedule: true,
    updateValiditySchedule: true,
    deleteValiditySchedule: true,
  },
};
