import { XCircleIcon } from "@heroicons/react/24/solid";
import { MouseEventHandler } from "react";
import {
  Slide,
  ToastContainer as ToastifyToastContainer
} from "react-toastify";
import { cntl } from "../../../util";
import { Icon } from "../..";

const toastCN = cntl`
    flex
    items-center
    justify-between
    bg-white
    shadow-lg
    rounded-3xl
    cursor-pointer
    p-4
    mb-3
`;

const CloseButton = ({
  closeToast
}: {
  closeToast: MouseEventHandler<HTMLDivElement>;
}) => (
  <div onClick={closeToast} className="mb-auto">
    <Icon Icon={XCircleIcon} />
  </div>
);

export const ToastContainer = () => (
  <ToastifyToastContainer
    position="bottom-center"
    autoClose={8000}
    hideProgressBar
    newestOnTop
    closeOnClick
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton={CloseButton}
    transition={Slide}
    toastClassName={() => toastCN}
    bodyClassName={() =>
      "flex items-start text-base font-primary text-black-lighter"
    }
  />
);
