import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { cntl } from "../../../util";
import { Icon } from "./Icon";

const iconCN = (isOpen: boolean, className?: string, right?: boolean) => cntl`
    transition
    ease-in-out
    duration-300
    transform
    ${isOpen ? (right ? "rotate-180" : "rotate-90") : ""}
    ${className ?? ""}
`;

interface ChevronProps {
  isOpen: boolean;
  className?: string;
  size?: number;
  color?: string;
  right?: boolean;
}

export const IconChevronToggle = ({
  isOpen,
  className,
  size,
  color,
  right
}: ChevronProps) => (
  <Icon
    Icon={right ? ChevronUpIcon : ChevronRightIcon}
    size={size ? size : 5}
    className={iconCN(isOpen, className, right)}
    color={color ? color : "black-lightest"}
  />
);
