import { IPermissions } from "../IPermissions";

// IMPORTANT: Don't set any permissions to false to prevent overwritting issues with multiple roles per user.
export const shareholderMeetingManagerPermissions: IPermissions = {
  shareholderMeetings: {
    readShareholderMeetingsTable: true,
    readShareholderMeetingsDetails: true,
    updateShareholderMeeting: true,
    addParticipant: true,
  },
};
