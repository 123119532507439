import { IPermissions } from "../IPermissions";

// IMPORTANT: Don't set any permissions to false to prevent overwritting issues with multiple roles per user.
export const adminPermissions: IPermissions = {
  tickets: {
    readTicketTable: true,
    readTicketDetails: true,
    readPurchaseDetails: true,
    readChipcardDetails: true,
    readLicensePlateDetails: true,
    readTicketUsageTable: true,
    validateTicket: true,
    refundTicket: true,
    restoreTicketCredit: true,
    updateLicensePlates: true,
    updateAnonymousCustomers: true,
    createBulkPurchase: true,
    resendTickets: true,
  },
  customers: {
    readCustomerTable: true,
    readPersonDetails: true,
    readCompanyDetails: true,
    readCustomerChipcards: true,
    readCustomerTickets: true,
    updatePerson: true,
    createPerson: true,
    updateCompany: true,
    createCompany: true,
    deleteCustomer: true,
    restoreCustomer: true,
    readCustomerLicensePlates: true,
    connectCustomerLicensePlate: true,
    disconnectCustomerLicensePlate: true,
    addPersonalizedChipcard: true,
    connectChipcard: true,
    disconnectChipcard: true,
    addCompanyContact: true,
    removeCompanyContact: true,
    updateCompanyContact: true,
    readCompanyContacts: true,
    readChipcardDetails: true,
    updateChipcard: true,
    blockChipcard: true,
    unblockChipcard: true,
    replaceChipcard: true,
    deleteChipcard: true,
    removeStakeholderLogo: true,
  },
  products: {
    readProductsTable: true,
    deleteProduct: true,
    readProductDetails: true,
    updateBicycleProduct: true,
    updateMotorcycleProduct: true,
    updateVehicleProduct: true,
    updatePedestrianProduct: true,
    updateMiscProduct: true,
    createProduct: true,
    productProperties: {
      setAll: true,
    },
  },
  productCategories: {
    readProductCategories: true,
    updateProductCategory: true,
  },
  licensePlates: {
    readLicensePlatesTable: true,
    readLicensePlateDetails: true,
    createLicensePlate: true,
    checkLicensePlateInfo: true,
    updateLicensePlateInfo: true,
    changeLicensePlateRegistrationStatus: true,
    updateLeaseInfo: true,
    clearLeaseInfo: true,
  },
  accounts: {
    readAccountsTable: true,
    readAccountDetails: true,
    createAccount: true,
    updateAccount: true,
    readAccountLicensePlates: true,
    connectAccountLicensePlate: true,
    disconnectAccountLicensePlate: true,
    sendPasswordResetEmail: true,
    readAccountChipcards: true,
    connectChipcard: true,
    disconnectChipcard: true,
    readAccountTickets: true,
    readChipcardDetails: true,
    updateChipcard: true,
    blockChipcard: true,
    unblockChipcard: true,
    replaceChipcard: true,
  },
  financialTransactions: {
    readFinancialTransactionsTable: true,
    updateFinancialTransactions: true,
    resendFinancialTransactions: true,
  },
  shareholders: {
    readShareholderShares: true,
  },
  deliveryMethods: {
    readDeliveryMethodsTable: true,
    deleteDeliveryMethod: true,
    addDeliveryMethod: true,
    updateDeliveryMethod: true,
  },
  ticketTemplates: {
    readTicketTemplatesTable: true,
    updateTicketTemplates: true,
  },
  salesChannels: {
    readSalesChannelsTable: true,
    addSalesChannel: true,
    updateSalesChannel: true,
    deleteSalesChannel: true,
  },
  salesChannelUnits: {
    readSalesChannelUnitsTable: true,
    readSalesChannelUnitDetails: true,
    addSalesChannelUnit: true,
    updateSalesChannelUnit: true,
    deleteSalesChannelUnit: true,
  },
  checkpoints: {
    readCheckpointsTable: true,
    readCheckpointDetails: true,
    addCheckpoint: true,
    updateCheckpoint: true,
    deleteCheckpoint: true,
  },
  checkpointTypes: {
    readCheckpointTypesTable: true,
    readCheckpointTypeDetails: true,
    addCheckpointType: true,
    updateCheckpointType: true,
    deleteCheckpointType: true,
  },
  paymentMethods: {
    readPaymentMethodsTable: true,
    readPaymentMethodDetals: true,
    addPaymentMethod: true,
    updatePaymentMethod: true,
    deletePaymentMethod: true,
  },
  paymentProviders: {
    readPaymentProvidersTable: true,
    readPaymentProviderDetails: true,
    addPaymentProvider: true,
    updatePaymentProvider: true,
    deletePaymentProvider: true,
  },
  validitySchedules: {
    readValiditySchedulesTable: true,
    readValiditySchedulesDetails: true,
    addValiditySchedule: true,
    updateValiditySchedule: true,
    deleteValiditySchedule: true,
  },
  shareholderMeetings: {
    readShareholderMeetingsTable: true,
    readShareholderMeetingsDetails: true,
    addShareholderMeeting: true,
    updateShareholderMeeting: true,
    addParticipant: true,
  },
};
