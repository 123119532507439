import { ChevronUpDownIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { components } from "react-select";
import CreateableSelect from "react-select/creatable";
import { Icon, Text } from "../../../components";
import "./form.css";

const DropdownIndicator: typeof components.DropdownIndicator = () => (
  <Icon Icon={ChevronUpDownIcon} classNameIcon="mx-3 black-lightest" />
);

const ClearIndicator: typeof components.ClearIndicator = (props) => (
  <components.DropdownIndicator
    {...props}
    isDisabled={false}
    className="cursor-pointer"
  >
    <Icon Icon={XCircleIcon} classNameIcon="mx-3 black-lightest" />
  </components.DropdownIndicator>
);

const MultiValueRemove: typeof components.MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <Icon Icon={XCircleIcon} classNameIcon="mx-3 black-lightest" />
  </components.MultiValueRemove>
);

const IndicatorSeparator: typeof components.IndicatorSeparator = ({
  // Because react-select has type {innerProps: any}, ESLint thinks this is an implicit any, but it isn't
  // eslint-disable-next-line react/prop-types
  innerProps
}) => (
  <span
    style={{
      alignSelf: "stretch",
      backgroundColor: "rgba(55, 53, 47, 0.09)",
      marginBottom: 8,
      marginTop: 8,
      width: 1
    }}
    {...innerProps}
  />
);

interface FormCreatableSearchProps {
  id: string;
  feedback?: boolean;
  invalid?: boolean;
  label: string;
  isDisabled?: boolean;
  components: object;
  className?: string;
}

export const FormCreatableSearch = (props: FormCreatableSearchProps) => {
  const { id, feedback, invalid, label, isDisabled, components } = props;

  const formSelectableSearch = (
    <div
      className={`focus:no-box-shadow ${
        isDisabled ? "cursor-not-allowed" : "cursor-default"
      }`}
    >
      <CreateableSelect
        {...props}
        components={{
          DropdownIndicator,
          IndicatorSeparator,
          ClearIndicator,
          MultiValueRemove,
          ...components
        }}
        styles={{
          // Typing `provided` to `object` prevents a memory leak in the browser tests.
          option: (provided: object, state) => ({
            ...provided,
            backgroundColor: state.isFocused
              ? "rgba(0,0,0,0.2)"
              : state.isSelected
              ? "rgba(0,0,0,0.1)"
              : "",
            color: state.isSelected ? "#37352f" : "#72706c",
            fontWeight: state.isSelected ? 600 : 400,
            paddingLeft: "0.75rem",
            paddingRight: "0.75rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
            transitionTimingFunction: "cubic-bezier(0.4, 0, 1, 1)",
            transitionDuration: "100ms",
            transitionProperty:
              "background-color, transform, filter, backdrop-filter",
            cursor: "pointer"
          }),
          // Typing `provided` to `object` prevents a memory leak in the browser tests.
          control: (provided: object, { isDisabled }) => ({
            ...provided,
            width: "100%",
            minHeight: "auto",
            boxShadow:
              "rgba(15, 15, 15, 0.1) 0 0 0 1px, rgba(15, 15, 15, 0.1) 0 2px 4px",
            border: "none",
            backgroundColor: isDisabled ? "rgba(239, 238, 234, 1)" : "#fff",
            overflow: "hidden",
            cursor: isDisabled ? "not-allowed" : "default"
          }),
          input: () => ({
            paddingTop: "0.3rem",
            paddingBottom: "0.25rem"
          }),
          menuList: () => ({
            paddingTop: 0,
            paddingBottom: 0,
            maxHeight: 300,
            boxShadow:
              "0 0 0 1px rgba(15,15,15,.05), 0 3px 6px rgba(15,15,15,.1), 0 9px 24px rgba(15,15,15,.2);",
            borderRadius: "0.25rem",
            overflow: "auto"
          }),
          // Typing `provided` to `object` prevents a memory leak in the browser tests.
          singleValue: (provided: object) => ({
            ...provided,
            color: "#72706c",
            opacity: 1,
            transition: "opacity 300ms"
          })
        }}
      />
      {invalid && feedback && (
        <div className="text-sm text-danger">{feedback}</div>
      )}
    </div>
  );

  return label ? (
    <label htmlFor={id} className="mb-0">
      <Text
        classNameText="py-1 px-4
  text-sm
  font-semibold
  uppercase
  font-primary
  text-black-lighter"
        text={label}
      />
      {formSelectableSearch}
    </label>
  ) : (
    formSelectableSearch
  );
};
