import { IPermissions } from "../IPermissions";

// IMPORTANT: Don't set any permissions to false to prevent overwritting issues with multiple roles per user.
export const financeAccountancyManagerPermissions: IPermissions = {
  tickets: {
    readTicketTable: true,
    readTicketDetails: true,
    readPurchaseDetails: true,
    readLicensePlateDetails: true,
    readTicketUsageTable: true,
    validateTicket: true,
    refundTicket: true,
    restoreTicketCredit: true,
    updateLicensePlates: true,
    createBulkPurchase: true,
  },
  products: {
    readProductsTable: true,
    readProductDetails: true,
    updateBicycleProduct: true,
    updateMotorcycleProduct: true,
    updateVehicleProduct: true,
    updatePedestrianProduct: true,
    updateMiscProduct: true,
    productProperties: {
      setPrice: true,
      setAfas: true,
    },
  },
  productCategories: {
    readProductCategories: true,
  },
  licensePlates: {
    readLicensePlatesTable: true,
    readLicensePlateDetails: true,
    checkLicensePlateInfo: true,
  },
  financialTransactions: {
    readFinancialTransactionsTable: true,
    updateFinancialTransactions: true,
    resendFinancialTransactions: true,
  },
  deliveryMethods: {
    readDeliveryMethodsTable: true,
  },
  ticketTemplates: {
    readTicketTemplatesTable: true,
  },
  salesChannels: {
    readSalesChannelsTable: true,
  },
  salesChannelUnits: {
    readSalesChannelUnitsTable: true,
    readSalesChannelUnitDetails: true,
  },
  checkpoints: {
    readCheckpointsTable: true,
    readCheckpointDetails: true,
  },
  checkpointTypes: {
    readCheckpointTypesTable: true,
    readCheckpointTypeDetails: true,
  },
  paymentMethods: {
    readPaymentMethodsTable: true,
    readPaymentMethodDetals: true,
    addPaymentMethod: true,
    updatePaymentMethod: true,
    deletePaymentMethod: true,
  },
  paymentProviders: {
    readPaymentProvidersTable: true,
    readPaymentProviderDetails: true,
    addPaymentProvider: true,
    updatePaymentProvider: true,
    deletePaymentProvider: true,
  },
  validitySchedules: {
    readValiditySchedulesTable: true,
    readValiditySchedulesDetails: true,
  },
  shareholderMeetings: {
    readShareholderMeetingsTable: true,
    readShareholderMeetingsDetails: true,
  },
};
