import { IPermissions } from "../IPermissions";

// IMPORTANT: Don't set any permissions to false to prevent overwritting issues with multiple roles per user.
export const applicationManagerPermissions: IPermissions = {
  products: {
    readProductsTable: true,
    readProductDetails: true,
    updateBicycleProduct: true,
    updateMotorcycleProduct: true,
    updateVehicleProduct: true,
    updatePedestrianProduct: true,
    updateMiscProduct: true,
    productProperties: {
      setTicketTemplates: true,
    },
  },
  deliveryMethods: {
    readDeliveryMethodsTable: true,
    deleteDeliveryMethod: true,
    addDeliveryMethod: true,
    updateDeliveryMethod: true,
  },
  productCategories: {
    readProductCategories: true,
    updateProductCategory: true,
  },
  ticketTemplates: {
    readTicketTemplatesTable: true,
    updateTicketTemplates: true,
  },
  salesChannels: {
    readSalesChannelsTable: true,
    addSalesChannel: true,
    updateSalesChannel: true,
    deleteSalesChannel: true,
  },
  salesChannelUnits: {
    readSalesChannelUnitsTable: true,
    readSalesChannelUnitDetails: true,
    addSalesChannelUnit: true,
    updateSalesChannelUnit: true,
    deleteSalesChannelUnit: true,
  },
  checkpoints: {
    readCheckpointsTable: true,
    readCheckpointDetails: true,
    addCheckpoint: true,
    updateCheckpoint: true,
    deleteCheckpoint: true,
  },
  checkpointTypes: {
    readCheckpointTypesTable: true,
    readCheckpointTypeDetails: true,
    addCheckpointType: true,
    updateCheckpointType: true,
    deleteCheckpointType: true,
  },
  paymentMethods: {
    readPaymentMethodsTable: true,
    readPaymentMethodDetals: true,
    addPaymentMethod: true,
    updatePaymentMethod: true,
    deletePaymentMethod: true,
  },
  paymentProviders: {
    readPaymentProvidersTable: true,
    readPaymentProviderDetails: true,
    addPaymentProvider: true,
    updatePaymentProvider: true,
    deletePaymentProvider: true,
  },
  validitySchedules: {
    readValiditySchedulesTable: true,
    readValiditySchedulesDetails: true,
    addValiditySchedule: true,
    updateValiditySchedule: true,
    deleteValiditySchedule: true,
  },
};
