import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
} from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { RoleEnum } from "../enums/RoleEnum";
import { getPermissionsBasedOnRoles } from "./role-permissions/RolePermissions";
import { IPermissions } from "./role-permissions/IPermissions";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
interface IAuthorizationContextState {
  roles: RoleEnum[];
  permissions: IPermissions;
}

interface IMsalAccountIdTokenClaims {
  roles: string[];
}

interface IAuthorizationProvider {
  children: ReactNode;
}

const AuthorizationContext = createContext<IAuthorizationContextState>(
  {} as IAuthorizationContextState,
);

export const AuthorizationProvider: FunctionComponent<
  IAuthorizationProvider
> = ({ children }) => {
  const { instance } = useMsal();
  const msalAccountIdTokenClaims = instance.getActiveAccount()
    ?.idTokenClaims as IMsalAccountIdTokenClaims;

  let roles: RoleEnum[] = [];

  if (msalAccountIdTokenClaims?.roles?.length > 0) {
    roles = msalAccountIdTokenClaims?.roles.map((role) => role as RoleEnum);
  }

  const permissions = getPermissionsBasedOnRoles(roles);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <AuthorizationContext.Provider value={{ roles, permissions }}>
        {children}
      </AuthorizationContext.Provider>
    </MsalAuthenticationTemplate>
  );
};

export const useAuthorizationContext = () => useContext(AuthorizationContext);
