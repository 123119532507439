import { Dialog, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  widthClass?: string;
}

export const ModalLayout = ({
  children,
  isOpen,
  onClose,
  widthClass
}: PropsWithChildren<ModalProps>) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={onClose}
    >
      <div className="my-8 flex min-h-screen items-end justify-center">
        <div className="fixed inset-0" onClick={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute h-screen w-screen bg-black-dark opacity-75" />
          </Transition.Child>
        </div>

        <div
          className={`z-10 my-auto flex justify-center ${
            widthClass ? widthClass : "w-1/2 xl:w-2/5 2xl:w-1/3"
          }`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto w-full rounded bg-white-off text-base shadow-lg">
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);
