import React, { FunctionComponent } from "react";
import { useBackendVersion } from "../resources/versionQueries";
import { useTranslation } from "react-i18next";

const Version: FunctionComponent = () => {
  const { backendVersion } = useBackendVersion();
  const { t } = useTranslation();

  return (
    <div className="px-4 py-2 font-semibold font-primary text-black-lighter">
      <div className="text-sm uppercase">{t("AppVersion.Title")}</div>
      <div className="text-sm">
        {t("AppVersion.Frontend")}: {process.env.REACT_APP_VERSION}
      </div>
      <div className="text-sm">
        {t("AppVersion.Backend")}: {backendVersion}
      </div>
      <div></div>
    </div>
  );
};
export default Version;
