import { IPermissions } from "../IPermissions";

// IMPORTANT: Don't set any permissions to false to prevent overwritting issues with multiple roles per user.
export const salesEmployeePermissions: IPermissions = {
  tickets: {
    readTicketTable: true,
    readTicketDetails: true,
    readLicensePlateDetails: true,
    readTicketUsageTable: true,
    validateTicket: true,
  },
};
