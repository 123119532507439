import { PropsWithChildren } from "react";

export const ModalFooter = ({ children }: PropsWithChildren<{}>) => (
  <div
    className={`flex
  space-x-3
  rounded-b
  border-t
  border-table-x
  bg-gray
  px-6
  py-4`}
  >
    {children}
  </div>
);
